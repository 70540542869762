import React from 'react';
import {Col, Container, Row} from "react-grid-system";
import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {ReactJkMusicPlayerInstance} from 'react-jinke-music-player'
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import {Artist, Introduction, Title, tracks, portrait, Summary} from "./content"
import coverimage from './media/cover1000x1000.jpg'
import ReactGA from 'react-ga4';
import spotify from "./media/spotify.png";

//Set up GA
ReactGA.initialize('G-NTTL20HM93');
ReactGA.send("pageview");

//Set up the track type
export interface Track {
    magician: string
    name: string
    mp3: string
    img: string
    description: string
}


class App extends React.Component {
    audioInstance: any = null;

    private getTracks = () => {
        return tracks.map((track, index) => {
            return (
                <Container className="track" key={index.toString()}>
                    <Row>
                        <Col sm={4} key="1"><img alt={track.magician} src={track.img}/></Col>
                        <Col sm={8} key="2"><h3>{track.magician}</h3>
                            <p>
                                <button
                                    onClick={() => this.audioInstance.playByIndex(index)}>Play
                                    now: {track.name}</button>
                            </p>
                            <p>{track.description}</p>
                        </Col>
                    </Row>

                </Container>)
        })
    }

    private getThumbs = () => {
        return tracks.map((track, index) => {
            return (
                <picture key={index.toString()}>
                    <img
                        src={track.img}
                        alt={track.name}
                        height="70"
                    />
                </picture>)
        })
    }

    private playlist = () => {
        return tracks.map(track => {
            return {
                name: track.name,
                musicSrc: track.mp3,
                cover: coverimage,
                singer: Artist
            }
        })
    }

    render = () => {
        return <div className="App">
            <header className="App-header">
                <ReactJkMusicPlayer
                    theme="dark"
                    audioLists={this.playlist()}
                    mode="full"
                    showThemeSwitch={false}
                    showDownload={false}
                    showPlayMode={false}
                    showDestroy={false}
                    showLyric={false}
                    showReload={false}
                    toggleMode={false}
                    mobileMediaQuery="(max-width: 100px)"
                    getAudioInstance={(i) => {
                        this.audioInstance = i
                    }}
                />
                <h1>{Title}</h1>
                <p>{Summary}</p>
                <p className="highlight">

                   <a href="https://open.spotify.com/album/2ahzdkuINsF3dccKldCDhH?si=MkhikdzXROiSyvF4lK1wtw"
                    style={{
                           display: "inline-block",
                           overflow: "hidden",
                           borderRadius: "13px",
                           width: "250px",
                           height: "83px",
                           paddingRight: "20px"
                       }}>
                       <img
                            src={spotify}
                            alt="Listen on Spotify"
                            style={{
                                borderRadius: "13px",
                                width: "250px",
                                height: "83px"
                            }}/>
                   </a>



                    <a href="https://geo.music.apple.com/us/album/nine-magicians/1606443403?itsct=music_box_badge&amp;itscg=30200&amp;app=music&amp;ls=1"
                       style={{
                           display: "inline-block",
                           overflow: "hidden",
                           borderRadius: "13px",
                           width: "250px",
                           height: "83px"
                       }}>
                        <img
                            src="https://tools.applemediaservices.com/api/badges/listen-on-apple-music/badge/en-us?size=250x83&amp;releaseDate=1643328000&h=3ce555486ad0b9abeb44106938aeb210"
                            alt="Listen on Apple Music"
                            style={{
                                borderRadius: "13px",
                                width: "250px",
                                height: "83px"
                            }}/>
                    </a>
                </p>
                <h2>Introducing the Magicians</h2>
            </header>
            <Carousel className="magician-carousel"
                      ariaLabel="Nine Magicians"
                      autoPlay={true}
                      interval={20000}
                      infiniteLoop={true}
                      renderThumbs={this.getThumbs}>
                {this.getTracks()}
            </Carousel>

            <Container className="intro">
                <Row>
                    <Col><h3>Behind the Music</h3></Col>
                </Row>
                <Row>
                    <Col sm={2}><img src={portrait} alt={Artist}/></Col>
                    <Col sm={10}>
                        <p> {Introduction} </p></Col>
                </Row>
            </Container>
        </div>
    }
}

export default App;
