import {Track} from "./App"

import track1 from "./media/1 - Albert's Equivalence.mp3"
import img1 from "./media/1.jpeg";
import track2 from "./media/2 - Nothing is Certain.mp3";
import img2 from "./media/2.jpeg";
import track3 from "./media/3 - Conflict and Cooperation.mp3";
import img3 from "./media/3.jpeg";
import track4 from "./media/4 - The Equation.mp3";
import img4 from "./media/4.jpeg";
import track5 from "./media/5 - Hello World.mp3";
import img5 from "./media/5.jpeg";
import track6 from "./media/6 - Chaos.mp3";
import img6 from "./media/6.jpeg";
import track7 from "./media/7 - Truth and Paradox.mp3";
import img7 from "./media/7.jpeg";
import track8 from "./media/8 - Fractals.mp3";
import img8 from "./media/8.jpeg";
import track9 from "./media/9 - The Exclusion Principle.mp3";
import img9 from "./media/9.jpeg";
import tw from "./media/tw.jpeg"

export const portrait : string = tw

export const Title: string = "Nine Magicians"
export const Artist: string = "Nine Magicians"

export const Summary: string = "A meditation on the concepts of some the 20th Century's most ground-breaking mathematics, physics, and computer science"

export const Introduction: string = "Nine Magicians are the brain child of Tom Weiss, who studied maths, physics, computer programming, and game theory. Each member of the band has been inspired by scientists whose ideas have stuck with them beyond their studies. Each track explores in music some of the key scientific discoveries of the 20th Century and the 'magicians' who developed them."

export const tracks: Track [] = [{
    "magician": "Albert Einstein 1879-1955",
    "name": "Albert's Equivalence",
    "mp3": track1,
    "img": img1,
    "description": "Einstein's famous formula expresses mass-energy equivalence. What it means is that there is this tremendous amount of energy hidden inside everyday objects. We only ever consider it in the context of nuclear power, but it's a significant part of our everyday world. Try and see what's going on just under the surface. This tune explores the enormous potential hidden in even the calmest scene we might see."
},
    {
        "magician": "Werner Heisenberg 1901-1976",
        "name": "Nothing is Certain",
        "mp3": track2,
        "img": img2,
        "description": "Heisenberg's uncertainty principle on the surface looks simple: you can either know the speed or the location of an object. It seems intuitive that you cannot measure both with exact accuracy. Still, Heisenberg took it a step further and proved that it is formally undefined. If a small enough particle has a defined location, then its speed is actually unclear. It's one of the most unintuitive proofs in quantum mechanics. Here, we lock one of the dimensions with the seven-note piano motif and explore the other dimensions that are still left open."
    },
    {
        "magician": "Lloyd Shapley 1923-2016",
        "name": "Conflict and Cooperation",
        "mp3": track3,
        "img": img3,
        "description": "Lloyd Shapley is one of the great innovators in game theory, the mathematical study of conflict and cooperation. He found exquisite proof for a mechanism to distribute credit between players in a game. The Shapley value is named after him. The call and response nature of the melody is meant to represent the nature of different players in the game as they co-operate and compete to maximize their outcomes in the game."
    },
    {
        "magician": "Erwin Schrodinger 1887-1961",
        "name": "The Equation",
        "mp3": track4,
        "img": img4,
        "description": "Schrodinger is most famous for the thought experiment about a cat. However, his genius is most apparent in the equation named after him that underpins all of the quantum theory. It is incredible how much of the universe can be explained by solving a set of quantum constraints against this simple equation. This track is also based on a simple concept and explores the beauty and simplicity of Schrodinger's work."
    },
    {
        "magician": "Dennis Ritchie 1941-2011",
        "name": "Hello, World!",
        "mp3": track5,
        "img": img5,
        "description": "Dennis Ritchie is the father of modern computing, famous for developing the C language and the infamous \"Hello World\" program. I fell in love with programming at the age of 10, but it wasn't until I learned C that I discovered how beautiful programming can be. There is something very special about elegant and straightforward code, and this track tries to reflect that feeling."
    },
    {
        "magician": "Ed Lorenz 1917-2008",
        "name": "Chaos",
        "mp3": track6,
        "img": img6,
        "description": "Ed Lorenz was the founder of chaos theory. It's known for the \"butterfly effect,\" where he described how the flap of a single butterfly's wings on one part of the globe can result in a Hurricane on the other. Chaos theory gives us the mathematical basis to understand how unpredictable events come about within very predictable and logical systems. This tune started out when Trump was elected to the White House and explores disorder and turbulence."
    },
    {
        "magician": "Bertrand Russel 1872-1970",
        "name": "Truth and Paradox",
        "mp3": track7,
        "img": img7,
        "description": "Bertrand Russell was primarily a philosopher and secondarily a mathematician. His epic tome, the Principia Mathematica, looked to demonstrate that all mathematical truths are logical truths. It was completed in 1927, and four years later, Kurt Godel published his famous proof that every formal system contains undecidable propositions. That is: not all mathematical truths are logical truths. This track illustrates the energy we can spend attempting to prove the impossible and the sadness in learning that the goal can never be achieved."
    },
    {
        "magician": "Benoit Mandelbrot 1924-2010",
        "name": "Fractals",
        "mp3": track8,
        "img": img8,
        "description": "Most people are now familiar with the Mandelbrot set. Still, few know about Benoit Mandelbrot, who first coined \"fractal.\" I started playing with fractals in the 1980s when the Mandelbrot set was still hard to calculate. I've always been struck by its beauty and how each subsequent level retains the original pattern, however far you dive in. This track reflects the endless descent and variations as we dive into the Mandelbrot set. Nevertheless, however far we go, we still end much as we began."
    },
    {
        "magician": "Wolfgang Pauli 1900-1958",
        "name": "The Exclusion Principle",
        "mp3": track9,
        "img": img9,
        "description": "Pauli's exclusion principle states that two fermions cannot simultaneously have the same quantum state in the same quantum system. This results in the way electrons surround atoms in layered shells that give rise to the chemical properties of materials we see around us. We can also interpret it as meaning that everything is uniquely individual. Most physicists often feel that we don't quite fit it, and this track is intended to represent that feeling."
    }
]
